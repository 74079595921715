<template>
  <a href="https://merchbook-ldfd-tele2.ru/" class="logo">
    <svg viewBox="0 0 100 37" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M87.0432.1494c3.763-.5309 7.204.3619 9.5489 2.5379 2.1449 1.9904 3.2269 4.8095 2.9683 7.7349-.4893 5.5294-3.223 9.9598-10.8341 17.259l-12.4566.4014s8.6769-7.736 11.9404-11.5156c2.1146-2.4493 3.0103-5.0931 2.1418-6.8554-.5504-1.1167-1.7862-1.6725-3.3087-1.5894V.1494zm-25.5256 4.384v7.1882l10.819-1.168V3.041l-10.819 1.4925zM52.7066 37V5.749l7.4238-1.0242V37h-7.4238zM23.3808 9.8038v6.0426l8.1777-.8826V8.676l-8.1777 1.1278zm-6.7184 27.196V10.7309l5.6631-.7813v27.0502h-5.6631zM0 13.0293v5.3418l14.6801-1.5853v-5.7812L0 13.0293zm4.684 5.6665v18.3041h5.375V18.1573l-5.375.5385zm18.6968 7.8021v-6.1538l5.3685-.4529v6.3189l-5.3685.2878zm-.0001 4.4344v6.0675h8.1777v-6.3096l-8.1777.2421zm38.1369-6.4825v-7.3215l7.0916-.5966v7.5378l-7.0916.3803zm-.0001 5.3512v7.1988h10.819v-7.5191l-10.819.3203zM33.722 36.9999V8.3773l6.4509-.89V37H33.722zm7.6549-6.6006v6.6005h9.3645V30.122l-9.3645.2773zM100 36.9999v-8.3431l-25.267.617v7.7261H100zM75.0623 15.8124C74.6464 7.1633 78.2507 1.8621 85.5906.3284l.0046 8.12c-1.5406.6371-3.0632 1.7961-2.7065 6.6825l-7.8264.6815z"
        fill="#fff"
      />
    </svg>
  </a>
</template>

<script>
export default {
  name: "Logo",
};
</script>

<style lang="scss" scoped>
.logo {
  svg {
    height: 22px;
    @include onlyMobile {
      height: 18px;
    }
  }
}
</style>
