<template>
  <nav>
    <div class="bg" @click="$emit('close')" />
    <ul class="nav">
      <li
        v-for="(item, i) of nav"
        :key="i"
        :class="{
          active: checkActive(item.path),
          open: item.open,
        }"
        class="nav__item"
      >
        <button type="button" @click="nav[i].open = !nav[i].open">
          {{ item.name }}
        </button>
        <ul class="list" :class="{ active: active == i + 1 }">
          <li
            v-for="(li, ind) of item.list"
            :key="ind"
            class="list__item"
            :class="{ active: checkActiveItem(li.path) }"
            @click="$emit('close')"
          >
            <router-link :to="li.path">
              {{ li.name }}
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "NavMobile",
  data() {
    return {
      active: 0,
      nav: [
        {
          name: "Форматы торговых точек",
          path: "/formats",
          list: [
            {
              name: "Формат FS/LS",
              path: "/formats?format=LS",
            },
            {
              name: "Формат FA/LA",
              path: "/formats?format=LA",
            },
          ],
          open: false,
        },
        {
          name: "Зонирование торговых точек",
          path: "/zones",
          list: [
            {
              name: "Входная группа",
              path: "/zones?zone=pink",
            },
            {
              name: "Торговый зал",
              path: "/zones?zone=blue",
            },
            {
              name: "Витрины",
              path: "/zones?zone=green",
            },
            {
              name: "Кассовая зона",
              path: "/zones?zone=orange",
            },
          ],
          open: false,
        },
        {
          name: "Правила размещения POSM",
          path: "/rules",
          list: [
            {
              name: "Общие правила использования",
              path: "/rules?rule=1",
            },
            {
              name: "Обязательные POSM",
              path: "/rules?rule=2",
            },
            {
              name: "Внешнее оформление",
              path: "/rules?rule=3",
            },
            {
              name: "Оформление внутри ТЦ",
              path: "/rules?rule=4",
            },
            {
              name: "Оформление торгового зала",
              path: "/rules?rule=5",
            },
            {
              name: "Оформление витрины",
              path: "/rules?rule=6",
            },
            {
              name: "Бренд-блок",
              path: "/rules?rule=7",
            },
            {
              name: "Оформление прикассовой зоны",
              path: "/rules?rule=8",
            },
          ],
          open: false,
        },
        {
          name: "Требования к фотоотчетам",
          path: "/reports",
          list: [
            {
              name: "Обязательные требования",
              path: "/reports",
            },
            {
              name: "Примеры обзорных фото",
              path: "/reports",
            },
          ],
          open: false,
        },
      ],
    };
  },
  mounted() {
    document.addEventListener("keydown", (e) => this.keyEsc(e));
    setTimeout(() => {
      this.nav = this.initOpen();
    }, 300);
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.keyEsc);
  },
  methods: {
    checkActive(path) {
      const routePath = this.$route.path;
      return routePath.includes(path);
    },
    checkActiveItem(path) {
      const docLocation = document.location.search;
      return path.includes(docLocation);
    },
    initOpen() {
      const nav = this.nav.map((el) => {
        return {
          ...el,
          open: this.$route.path == el.path,
        };
      });
      return nav;
    },
    keyEsc(e) {
      if (e.keyCode === 27) this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.bg {
  width: 100%;
}
nav {
  position: fixed;
  top: 45px;
  height: calc(100vh - 45px);
  display: grid;
  grid-template-columns: 1fr 320px;
  width: 100%;
  overflow: auto;
  transition: all 0.3s;
}
.nav {
  // width: calc(100% - 36px);
  background: var(--mainColor);
  padding-left: 0;
  padding: 0 18px;
  &__item {
    padding: 18px 0;
    position: relative;
    margin: 0;
    &::after {
      content: "";
      width: 14px;
      height: 8px;
      background: url("../assets/icon/arrow.svg") no-repeat;
      position: absolute;
      right: 0;
      top: 24px;
      left: auto;
      transform: rotate(180deg);
      transition: all 0.3s;
      pointer-events: none;
    }
    button {
      color: #8f9399;
      transition: all 0.3s;
      font-size: 16px;
      line-height: 22px;
      font-weight: bold;
      width: 100%;
      text-align: left;
      &:active {
        color: #ffffff;
      }
    }
    &.active button {
      color: #ffffff;
    }
    &.open .list {
      height: auto;
      padding-top: 18px;
    }
    &.open::after {
      transform: rotate(0deg);
    }
  }
  .list {
    padding-top: 18px;
    padding-left: 9px !important;
    height: 0;
    overflow: hidden;
    padding: 0;
    width: calc(100% - 9px);
    transition: all 0.5s;
    &__item {
      margin-bottom: 14px;
      &.active {
        a {
          color: #ffffff;
        }
      }
      a {
        font-weight: normal;
        color: #8f9399;
        &:active {
          color: #ffffff;
        }
      }
    }
    & .list__item:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
