<template>
  <div class="page">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: { Header, Footer },
  name: "App",
};
</script>

<style lang="scss" src="@/style/index.scss"></style>
