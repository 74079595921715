<template>
  <button class="button-nav" type="button">
    <div class="line" />
    <div class="line" />
    <div class="line" />
  </button>
</template>

<script>
export default {
  name: "ButtonNav",
};
</script>

<style lang="scss" scoped>
.button-nav {
  display: none;
  @include tablet {
    display: flex;
    flex-direction: column;
    width: 18px;
    height: 14px;
    justify-content: space-between;
  }
  .line {
    width: 100%;
    border-radius: 1px;
    border: 1px solid #ffffff;
    transition: all 0.3s;
  }
  &.open {
    height: 18px;
    & .line:nth-child(2) {
      display: none;
    }
    & .line:nth-child(1) {
      position: relative;
      top: 8px;
      transform: rotate(45deg);
    }
    & .line:nth-child(3) {
      position: relative;
      top: -8px;
      transform: rotate(-45deg);
    }
  }
}
</style>
