import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home.vue"),
  },
  // {
  //   path: "/kit",
  //   name: "Kit",
  //   component: () => import(/* webpackChunkName: "about" */ "../views/Kit.vue"),
  // },
  {
    path: "/formats",
    name: "Formats",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Formats.vue"),
  },
  {
    path: "/zones",
    name: "Zones",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Zones.vue"),
  },
  {
    path: "/rules",
    name: "Rules",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Rules.vue"),
  },
  {
    path: "/reports",
    name: "PhotoReports",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PhotoReports.vue"),
  },
  {
    path: "/instructions",
    name: "Instructions",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Instructions.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (from.name == "Zones" && to.name == "Zones") {
      return;
    } else if (to.hash) {
      return {
        selector: to.hash,
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return {
        left: 0,
        top: 0,
      };
    }
  },
});

export default router;
