<template>
  <footer class="footer">
    <div class="container">
      <p class="footer__text mb16">
        Информация строго конфиденциальна и не подлежит передаче третьим лицам.
      </p>
      <p class="footer__text">© Tele2 Россия, {{ setCurrentYear() }}</p>
      <ButtonUp
        v-if="$route.path !== '/'"
        :is-show="isShow"
        @click="onScrollToTop()"
      />
    </div>
  </footer>
</template>

<script>
import ButtonUp from "./ButtonUp.vue";
export default {
  components: { ButtonUp },
  name: "Footer",
  data() {
    return {
      isShow: false,
    };
  },
  mounted() {
    document.addEventListener("scroll", this.myElementVisibility);
  },
  unmounted() {
    document.removeEventListener("scroll", this.myElementVisibility);
  },
  methods: {
    myElementVisibility() {
      if (window.pageYOffset > 100) this.isShow = true;
      else this.isShow = false;
    },
    onScrollToTop() {
      document.documentElement.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    setCurrentYear() {
      let today = new Date()

      return today.getFullYear()
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background: $color-gray0;
  padding: 16px 0 24px 0;
  &__text {
    color: #ffffff;
    opacity: 0.5;
    font-size: 12px;
    line-height: 140%;
  }
}
.mb16 {
  margin-bottom: 16px;
}
</style>
