<template>
  <button
    class="button-up"
    :class="{ showed: isShow }"
    type="button"
    @click="$emit('click')"
  >
    <svg
      width="20"
      height="23"
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 22C9 22.5523 9.44772 23 10 23C10.5523 23 11 22.5523 11 22L9 22ZM10.7071 6.29289C10.3166 5.90237 9.68342 5.90237 9.29289 6.29289L2.92893 12.6569C2.53841 13.0474 2.53841 13.6805 2.92893 14.0711C3.31946 14.4616 3.95262 14.4616 4.34315 14.0711L10 8.41421L15.6569 14.0711C16.0474 14.4616 16.6805 14.4616 17.0711 14.0711C17.4616 13.6805 17.4616 13.0474 17.0711 12.6569L10.7071 6.29289ZM11 22L11 7L9 7L9 22L11 22Z"
        fill="white"
      />
      <line
        x1="1"
        y1="1"
        x2="19"
        y2="1"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: "ButtonUp",
  props: {
    isShow: {
      type: Boolean,
      defaulf: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.button-up {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 19px;
  right: 24px;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: #34383f;
  opacity: 0;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
  z-index: 10;
  &.showed {
    opacity: 0.8;
  }
  &:hover {
    background: var(--mainColor);
  }
  &:active {
    background: #23262d;
    box-shadow: none;
  }
  @include onlyMobile {
    right: 15px;
    bottom: 16px;
  }
}
</style>
