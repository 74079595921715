<template>
  <nav>
    <ul class="nav">
      <li
        v-for="(item, i) of nav"
        :key="i"
        class="nav__item"
        @mouseenter="active = i + 1"
        @mouseleave="active = 0"
      >
        <router-link
          :to="item.path"
          :class="{ active: checkActive(item.path) }"
        >
          {{ item.name }}
        </router-link>
        <ul class="list" :class="{ active: active == i + 1 }">
          <li v-for="(li, ind) of item.list" :key="ind" class="list__item">
            <router-link :to="li.path">
              {{ li.name }}
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Nav",
  data() {
    return {
      active: 0,
      nav: [
        {
          name: "Форматы торговых точек",
          path: "/formats",
          list: [
            {
              name: "Формат FS/LS",
              path: "/formats?format=LS",
            },
            {
              name: "Формат FA/LA",
              path: "/formats?format=LA",
            },
          ],
        },
        {
          name: "Зонирование торговых точек",
          path: "/zones",
          list: [
            {
              name: "Входная группа",
              path: "/zones?zone=pink",
            },
            {
              name: "Торговый зал",
              path: "/zones?zone=blue",
            },
            {
              name: "Витрины",
              path: "/zones?zone=green",
            },
            {
              name: "Кассовая зона",
              path: "/zones?zone=orange",
            },
          ],
        },
        {
          name: "Правила размещения POSM",
          path: "/rules",
          list: [
            {
              name: "Общие правила использования",
              path: "/rules?rule=1",
            },
            {
              name: "Обязательные POSM",
              path: "/rules?rule=2",
            },
            {
              name: "Внешнее оформление",
              path: "/rules?rule=3",
            },
            {
              name: "Оформление внутри ТЦ",
              path: "/rules?rule=4",
            },
            {
              name: "Оформление торгового зала",
              path: "/rules?rule=5",
            },
            {
              name: "Оформление витрины",
              path: "/rules?rule=6",
            },
            {
              name: "Бренд-блок",
              path: "/rules?rule=7",
            },
            {
              name: "Оформление прикассовой зоны",
              path: "/rules?rule=8",
            },
          ],
        },
        {
          name: "Требования к фотоотчетам",
          path: "/reports",
          list: [
            {
              name: "Обязательные требования",
              path: "/reports",
            },
            {
              name: "Примеры обзорных фото",
              path: "/reports",
            },
          ],
        },
      ],
    };
  },
  methods: {
    checkActive(path) {
      const routePath = this.$route.path;
      return routePath.includes(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 18px;
  margin-left: 27px;
  padding-left: 0;
  @include tablet {
    display: none;
  }
  &__item {
    a {
      font-size: 13px;
      line-height: 18px;
      color: $color-nav;
      transition: all 0.3s;
      position: relative;
      &:hover {
        color: #bdbcbc;
      }
      &:active {
        color: #ffffff;
      }
      &.active {
        color: #ffffff;
      }
    }
  }
  li {
    margin-bottom: 0;
    &::after {
      display: none;
    }
  }
  .list {
    position: absolute;
    padding-left: 0;
    background: var(--mainColor);
    padding: 16px;
    border-radius: 0px 0px 4px 4px;
    width: max-content;
    min-width: calc(100% - 16px);
    opacity: 0;
    transition: all 0.3s;
    pointer-events: none;
    &.active {
      opacity: 1;
      pointer-events: all;
    }
    &__item {
      margin-bottom: 10px;
      &:hover a {
        color: #bdbcbc;
      }
      &:active a {
        color: #ffffff;
      }
      &::after {
        display: none;
      }
    }
    & .list__item:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
