const breakpoints_window = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 1024,
  xl: 1200,
  xxl: 1400,
};

/**
 * Миксин добавляет глобальные переменные:
 * device {String} (mobile, tablet, desktop)
 * breakpoints {String} (xs, sm, md, lg, xl, xxl)
 */
export default {
  computed: {
    device() {
      if (
        this.breakpoints_window === "xs" ||
        this.breakpoints_window === "sm"
      ) {
        return "mobile";
      } else if (
        this.breakpoints_window === "md" ||
        this.breakpoints_window === "lg"
      ) {
        return "tablet";
      }
      return "desktop";
    },
  },
  data() {
    return {
      breakpoints_window: "xs",
    };
  },
  created() {
    this.onResizeDevice();
    window.addEventListener("resize", this.onResizeDevice);
  },
  methods: {
    onResizeDevice() {
      const width = window.innerWidth;

      if (width <= breakpoints_window.sm) {
        this.breakpoints_window = "xs";
      } else if (
        width > breakpoints_window.sm &&
        width <= breakpoints_window.md
      ) {
        this.breakpoints_window = "sm";
      } else if (
        width > breakpoints_window.md &&
        width <= breakpoints_window.lg
      ) {
        this.breakpoints_window = "md";
      } else if (
        width > breakpoints_window.lg &&
        width <= breakpoints_window.xl
      ) {
        this.breakpoints_window = "lg";
      } else if (
        width > breakpoints_window.xl &&
        width <= breakpoints_window.xxl
      ) {
        this.breakpoints_window = "xl";
      } else if (width > breakpoints_window.xxl) {
        this.breakpoints_window = "xxl";
      }
    },
    findParent(el, cls) {
      while ((el = el.parentElement) && !el.classList.contains(cls));
      return el;
    },
  },
};
